<template>
  <el-dialog class="exam-report-dialog" :visible.sync="visible" top="60px" :before-close="close" @closed="closed" destroy-on-close>
    <div class="print-button back-top" @click="backTop">回到顶部</div>
    <div class="print-button" @click="exportPdf">下载报告</div>
    <div class="report-container" id="examReport">
      <div class="top">
        <img :src="require('@assets/logo/report-logo.png')" height="38px" alt="" />
        <div>
          https://enter.yuantusoft.com/home
          <img :src="require('@assets/logo/tencent-cloud-qrcode.png')" height="44px" width="44px" alt="" />
        </div>
      </div>
      <h1 style="font-size: 36px; margin-bottom: 40px">{{ organizationName }}-{{ examInfo.postNames.value }}整体考试报告</h1>
      <div class="block">
        <p class="title">一、基本信息</p>
        <h1><span>1.1</span><span>基本信息</span></h1>
        <ul class="info">
          <li v-for="(item, key, index) in examInfo" :style="{ width: index > 4 ? '50%' : '100%' }" :key="index">
            {{ item.label }}：{{ item.value }}
          </li>
        </ul>
        <h1><span>1.2</span><span>分数分布</span></h1>
        <div ref="distributionChart" class="distributionChart"></div>
        <el-table :data="distributionList" class="yt-table">
          <el-table-column label="分数段" prop="space" align="center" />
          <el-table-column label="人数" prop="num" align="center" />
          <el-table-column label="占总人数百分比" align="center">
            <template slot-scope="scope">{{ $roundFloat(scope.row.frequency, 2) }}%</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <p class="title">二、总体技能掌握情况</p>
        <h1><span>2.1</span><span>总体技能分析</span></h1>
        <div ref="skillOverallRadarChart" class="skillOverallRadarChart"></div>
        <div ref="skillOverallBarChart" class="skillOverallBarChart" :style="{ height: `${skillOverallList.length * 70 + 140}px` }"></div>
        <el-table :data="skillOverallList" class="yt-table">
          <el-table-column label="技能名称" prop="abilityName" align="center" />
          <el-table-column label="描述" prop="description" align="center" />
          <el-table-column label="平均掌握率" align="center">
            <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
          </el-table-column>
        </el-table>
        <template v-if="skillList.length > 0">
          <h1><span>2.2</span><span>详细技能分析</span></h1>
        </template>
        <template v-for="(item, index) in skillList">
          <div :style="{ marginTop: index === 0 ? '30px' : '80px' }" class="skill-item" :key="index">
            <h2>2.2.{{ index + 1 }} {{ item.abilityName }}详细技能掌握情况</h2>
            <div
              class="radar-chart"
              :class="[`skill-${item.abilityScoreRateVOS.length > 4 ? 4 : item.abilityScoreRateVOS.length}`]"
              :ref="'subSkillRadarChart-' + item.id"
            ></div>
            <div class="bar-chart" :ref="'subSkillBarChart-' + item.id"></div>
            <el-table :data="item.abilityScoreRateVOS" class="yt-table">
              <el-table-column label="技能名称" prop="abilityName" align="center" />
              <el-table-column label="描述" prop="description" align="center" />
              <el-table-column label="平均掌握率" align="center">
                <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </div>
      <div class="block">
        <p class="title" style="margin-bottom: 0">三、本场考试答题情况</p>
        <div id="questionPieChart"></div>
        <div id="questionBarChart"></div>
      </div>
      <div class="block" v-if="comment !== ''">
        <p class="title">四、本场考试评价</p>
        <p class="comment">{{ comment }}</p>
      </div>
      <div class="block" style="margin-bottom: 60px">
        <p class="title">{{ comment === '' ? '四' : '五' }}、考生排名</p>
        <el-table :data="rankList" class="yt-table">
          <el-table-column label="名次" prop="rank" align="center" />
          <el-table-column label="姓名" prop="userName" align="center" />
          <el-table-column label="总分" prop="score" align="center" />
        </el-table>
      </div>
      <div style="font-size: 20px; color: #999999">源图数字人才管理平台</div>
      <div style="font-size: 20px; color: #999999">- 精准测评 精准培训 精准识别 精准任用 -</div>
    </div>
  </el-dialog>
</template>

<script>
import barLineChart from './reportCharts/barLineChart'
import radarChart from './reportCharts/radarChart'
import barHorizontalChart from './reportCharts/barHorizontalChart'
import barDoubleChart from './reportCharts/barDoubleChart'
import pieChart from './reportCharts/pieLoopChart'
import barStackChart from './reportCharts/barStackChart'
import { pdfD2 } from '@util/pdfDown2'
import examProcessApi from '@api/examProcess'
import examResultApi from '@api/examResult'
import examManageApi from '@api/examManage'

export default {
  name: 'ExamReport',
  data() {
    return {
      visible: false,
      formData: {},
      organizationName: '',
      examInfo: {
        examName: {
          label: '测评主题',
          value: ''
        },
        description: {
          label: '测评简介',
          value: ''
        },
        postNames: {
          label: '测评专业',
          value: ''
        },
        startTime: {
          label: '开始时间',
          value: ''
        },
        endTime: {
          label: '结束时间',
          value: ''
        },
        type: {
          label: '出卷方式',
          value: ''
        },
        passScore: {
          label: '通过分数',
          value: ''
        },
        limitTime: {
          label: '测评时长',
          value: ''
        },
        candidateNum: {
          label: '参与人数',
          value: '120人'
        },
        highestScore: {
          label: '测评最高分',
          value: ''
        },
        lowestScore: {
          label: '测评最低分',
          value: ''
        },
        averageScore: {
          label: '测评平均分',
          value: ''
        },
        medianScore: {
          label: '测评中位数',
          value: ''
        }
      }, //基本信息
      distributionChart: null,
      distributionList: [], //分数分布
      skillOverallRadarChart: null, //技能整体雷达图
      skillOverallBarChart: null, //技能整体柱状图
      skillOverallList: [], //技能整体表格
      skillList: [], //技能详细数据
      rankList: [],
      comment: '',
      questionPieChart: null
    }
  },
  methods: {
    open(payload) {
      this.visible = true
      this.formData = payload
      this.$nextTick(() => {
        this.getBaseInfo()
        this.getMainAbilityRate()
        this.getSubAbilityRate()
        this.getQuestionNum()
        this.getScore()
        this.getExamRank()
        this.getScoreStatistics()
        this.getExamComment()
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.distributionChart.clear()
      this.skillOverallRadarChart.clear()
      this.skillOverallBarChart.clear()
    },
    getBaseInfo() {
      examResultApi.getAllSimple(this.formData).then(res => {
        Object.keys(this.examInfo).forEach(item => {
          this.examInfo[item].value = res.res[item]
        })
        this.organizationName = res.res.organizationName
        this.examInfo.startTime.value = this.$formatTime(this.examInfo.startTime.value, 'yyyy-MM-dd')
        this.examInfo.endTime.value = this.$formatTime(this.examInfo.endTime.value, 'yyyy-MM-dd')
      })
    },
    getScoreStatistics() {
      //分数分布
      examProcessApi.getScoreStatistics(this.formData, 10).then(res => {
        this.distributionList = res.res
        let barOption = JSON.parse(JSON.stringify(barLineChart.option))
        barOption.xAxis.data = res.res.map(item => {
          return item.space
        })
        barOption.yAxis[1].axisLabel.formatter = (value, index) => {
          return this.$roundFloat(value, 0) + '%'
        }
        barOption.series[0].data = res.res.map(item => {
          return item.num
        })
        barOption.series[1].data = res.res.map(item => {
          return item.frequency
        })
        this.distributionChart = this.$echarts.init(this.$refs.distributionChart)
        this.distributionChart.setOption(barOption)
      })
    },
    getMainAbilityRate() {
      //获取主技能分析
      examProcessApi.getMainAbilityRate(this.formData).then(res => {
        this.skillOverallList = res.res
        //技能整体掌握雷达图
        let radarOption = JSON.parse(JSON.stringify(radarChart.option))
        radarOption.radar[0].indicator = res.res.map(item => {
          return { name: item.abilityName, max: 1 }
        })
        radarOption.series[0].data[0].value = res.res.map(item => {
          return item.averageRate
        })
        this.skillOverallRadarChart = this.$echarts.init(this.$refs.skillOverallRadarChart)
        this.skillOverallRadarChart.setOption(radarOption)
        this.$nextTick(() => {
          //技能整体掌握柱状图
          let barOption = JSON.parse(JSON.stringify(barHorizontalChart.option))
          barOption.yAxis[0].data = res.res.map(item => {
            return item.abilityName
          })
          barOption.yAxis[0].axisLabel.interval = 0
          barOption.yAxis[1].data = res.res.map(item => {
            return this.$roundFloat(item.averageRate, 2) + '%'
          })
          barOption.series[0].data = res.res.map(item => {
            return this.$roundFloat(item.averageRate, 2)
          })
          this.skillOverallBarChart = this.$echarts.init(this.$refs.skillOverallBarChart)
          this.skillOverallBarChart.setOption(barOption)
        })
      })
    },
    getSubAbilityRate() {
      examProcessApi.getSubAbilityRate(this.formData).then(res => {
        this.skillList = res.res
          .filter(skill => {
            return skill.abilityScoreRateVOS.length > 0
          })
          .map(skill => {
            return {
              id: this.$generateUUID(),
              subSkillRadarChart: null,
              subSkillBarChart: null,
              ...skill
            }
          })
        this.$nextTick(() => {
          this.skillList = this.skillList.map(skill => {
            //技能详细掌握雷达图
            try {
              let radarOption = JSON.parse(JSON.stringify(radarChart.option))
              if (skill.abilityScoreRateVOS.length < 3) {
                radarOption.radar[0].startAngle = 180
              }
              radarOption.radar[0].indicator = skill.abilityScoreRateVOS.map(item => {
                return { name: item.abilityName, max: 1 }
              })
              radarOption.radar[0].axisName.formatter = value => {
                return value.length > 10 ? value.slice(0, 9) + '...' : value
              }
              radarOption.series[0].data[0].value = skill.abilityScoreRateVOS.map(item => {
                return item.averageRate
              })
              radarOption.series[0].data[0].itemStyle.color = '#7CAEEF'
              radarOption.series[0].data[0].itemStyle.lineStyle.color = '#7CAEEF'
              skill.subSkillRadarChart = this.$echarts.init(this.$refs[`subSkillRadarChart-${skill.id}`][0])
              skill.subSkillRadarChart.setOption(radarOption)
              //技能详细掌握雷达图
              let barOption = JSON.parse(JSON.stringify(barDoubleChart.option))
              barOption.yAxis.data = skill.abilityScoreRateVOS.map(item => {
                return item.abilityName
              })
              barOption.yAxis.axisLabel.interval = 0
              barOption.series[0].data = skill.abilityScoreRateVOS.map(item => {
                return this.$roundFloat(item.averageRate, 2)
              })
              skill.subSkillBarChart = this.$echarts.init(this.$refs[`subSkillBarChart-${skill.id}`][0])
              skill.subSkillBarChart.setOption(barOption)
            } catch (e) {}
            return skill
          })
        })
      })
    },
    getQuestionNum() {
      //题目数量
      examProcessApi.getQuestionNum(this.formData).then(res => {
        //答题情况饼图
        let pieOption = JSON.parse(JSON.stringify(pieChart.option))
        pieOption.series[0].data = res.res.map(item => {
          return { value: item.questionCount, name: item.abilityName }
        })
        this.questionPieChart = this.$echarts.init(document.getElementById('questionPieChart'))
        this.questionPieChart.setOption(pieOption)
      })
    },
    getScore() {
      examProcessApi.getScore(this.formData).then(res => {
        //答题情况柱状图
        let barOption = JSON.parse(JSON.stringify(barStackChart.option))
        barOption.xAxis.data = res.res.map(item => {
          return item.abilityName
        })
        barOption.series[0].data = res.res.map(item => {
          return item.points === 0 ? null : item.points
        })
        barOption.series[1].data = res.res.map(item => {
          return item.lostPoints === 0 ? null : item.lostPoints
        })
        this.questionBarChart = this.$echarts.init(document.getElementById('questionBarChart'))
        this.questionBarChart.setOption(barOption)
      })
    },
    getExamComment() {
      examManageApi.getExamComment(this.formData.examId).then(res => {
        if (res.res.length > 0) {
          this.comment = res.res[0].comment
        }
      })
    },
    getExamRank() {
      //获取排名
      examProcessApi.getExamRank(this.formData).then(res => {
        this.rankList = res.res
      })
    },
    exportPdf() {
      pdfD2(document.getElementById('examReport'), '考试结果分析报告.pdf')
        .then(() => {
          this.$message.success('导出成功!')
        })
        .catch(() => {
          this.$message.error('导出失败!')
        })
        .finally(() => {
          this.$parent.loading = false
        })
    },
    backTop() {
      document.querySelector('.exam-report-dialog').scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/reportVer1';
::v-deep .el-dialog {
  width: 1240px;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.print-button {
  position: fixed;
  top: 500px;
  right: 100px;
  width: 140px;
  padding: 25px 40px;
  height: 140px;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  background: #448bff;
  box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
  border-radius: 100px;
  cursor: pointer;
}
.back-top {
  background-color: #ff6060;
  box-shadow: 0 3px 6px rgba(255, 159, 159, 0.5);
  top: 330px;
}
</style>
