const questionTypeOption = {
  color: ['#FFBB00', '#448BFF', '#FFDB7A', '#4CE47A', '#FF5050'],
  title: {
    left: 'center',
    textStyle: {
      color: '#999',
      fontWeight: 'normal',
      fontSize: 14
    }
  },
  series: [
    {
      type: 'pie',
      radius: ['50%', '70%'],
      left: 'left',
      avoidLabelOverlap: false,
      label: {
        formatter: (data, idx) => {
          return `${data.data.name}:${data.data.value}%`
        },
        rich: {
          time: {
            fontSize: 10,
            color: '#999'
          }
        },
        color: '#333'
      },
      data: [
        {
          name: '代码题',
          value: 16
        },
        {
          name: '单选题',
          value: 62
        },
        {
          name: '判断题',
          value: 10
        },
        {
          name: '多选题',
          value: 0
        },
        {
          name: '填空题',
          value: 16
        }
      ]
    }
  ]
}
const pointDistribution = {
  color: ['#FFBB00', '#448BFF'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    },
    formatter: data => {
      return `<p>${data[0].name}</p>
<p><span>${data[0].seriesName}: </span>${data[0].value}</p>
<p><span>${data[1].seriesName}: </span><span>${data[1].value}%</span></p>`
    }
  },
  legend: {
    data: ['人数', '比率']
  },
  xAxis: [
    {
      type: 'category',
      data: [],
      axisPointer: {
        type: 'shadow'
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: '人数',
      min: 0,
      max: 40,
      interval: 5,
      axisLabel: {
        formatter: '{value} 位'
      }
    },
    {
      type: 'value',
      name: '比率',
      min: 0,
      max: 100,
      interval: 10,
      axisLabel: {
        formatter: '{value} %'
      }
    }
  ],
  series: [
    {
      name: '人数',
      type: 'bar',
      data: []
    },
    {
      name: '比率',
      type: 'line',
      yAxisIndex: 1,
      data: []
    }
  ]
}
const abilityOption = {
  color: ['#B7D8F2'],
  title: {
    text: '技能掌握情况',
    top: '5px'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    show: false,
    data: ['技能掌握情况']
  },
  grid: {
    // 这一块可以调整标题和图之间的距离
    top: '35px',
    left: '3%',
    right: '4%',
    bottom: '0',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    splitLine: {
      show: false
    }
    // boundaryGap: [0, 0.01]
  },
  yAxis: {
    type: 'category',
    data: [],
    splitNumber: 2,
    minInterval: 45
    // boundaryGap: [0.2, 0.2]
  },
  series: []
}
export { questionTypeOption, pointDistribution, abilityOption }
