<template>
  <div class="result-main yt-flex-layout yt-flexColumns" style="padding-top: 10px">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb v-if="!isShowBread" class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/manage/exam/list` }">考试列表</el-breadcrumb-item>
        <el-breadcrumb-item>考试结果</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="exam-title yt-flex-layout">
      <div class="title-info yt-flex-layout" v-for="(exam, eIndex) in examInfo" :key="eIndex">
        <span style="white-space: nowrap">{{ exam.name }}</span>
        <div v-if="exam.key === 'postNames'" class="yt-flex-layout" style="align-items: center">
          <span class="tags-span right-span" v-for="(v, vIndex) in exam.value.filter((item, iIndex) => iIndex < 3)" :key="vIndex">{{ v }}</span>
          <el-popover
            style="margin-left: 5px"
            width="158"
            v-if="exam.value.length > 3"
            placement="top"
            popper-class="yt-table-tag-popover"
            trigger="hover"
          >
            <div class="tag-list">
              <div class="tag-item" v-for="(v, cIndex) in exam.value" :key="cIndex">
                {{ v }}
              </div>
            </div>
            <div slot="reference" class="dots">
              <YTIcon :href="'#icon-gengduo1'" />
            </div>
          </el-popover>
        </div>
        <div v-else-if="exam.key === 'startTime' || exam.key === 'endTime'">
          <span class="right-span">{{ $formatTime(exam.value, 'yyyy-MM-dd hh:mm:ss') }}</span>
        </div>
        <div v-else>
          <el-tooltip :disabled="exam.key !== 'description'" effect="light">
            <div>
              <span class="right-span">{{ exam.key === 'limitTime' ? `${exam.value} 分钟` : exam.value }}</span>
            </div>
            <div slot="content">
              <p>{{ exam.value }}</p>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <div class="tabs-style yt-flex-layout">
      <el-tabs :disabled="loading" v-model="activeName" @tab-click="handleName">
        <el-tab-pane label="整体考试情况" name="first" />
        <el-tab-pane label="人员考试情况" name="second" />
        <el-tab-pane label="技能考试情况" name="skill" />
      </el-tabs>
      <el-button
        :loading="loading"
        v-show="activeName === 'first'"
        @click="exportPdf"
        size="small"
        class="export-btn search-btn"
        v-text="'PDF报告导出'"
      />
      <el-button
        :loading="loading"
        v-show="activeName === 'skill'"
        @click="exportSkillPdf"
        size="small"
        class="export-btn search-btn"
        v-text="'PDF报告导出'"
      />
      <div v-show="activeName === 'second'" class="yt-flex-layout search-and-button-style">
        <el-input
          :disabled="loading"
          v-model="nameBySearch"
          class="el-input-style"
          size="small"
          @keyup.enter.native="toSearch"
          placeholder="通过姓名搜索(回车搜索)"
        >
          <template slot="append"><YTIcon :href="'#icon-sousuo'" @click="toSearch"/></template>
        </el-input>
        <el-button v-if="!external" :loading="loading" size="small" class="search-btn" @click="showRetry" v-text="'发布重考'" />
        <el-button
          :loading="loading"
          size="small"
          class="el-button--default"
          style="margin-left: 10px"
          @click="importAllReports"
          v-text="'导出员工报告'"
        />
        <el-button :loading="loading" size="small" class="el-button--default" @click="examPInfo" v-text="'导出员工信息'" />
      </div>
    </div>

    <div style="padding: 0 10px; height: calc(100% - 155px); overflow-y: auto" v-show="activeName === 'first'">
      <overallExamination ref="overallRef" :examType="examType" />
    </div>
    <div style="padding: 0 10px" v-show="activeName === 'second'">
      <personnelTestSituation ref="testSituation" @exportSingleReport="exportSingleReport" />
    </div>
    <div style="height: calc(100% - 143px); overflow-y: auto" v-show="activeName === 'skill'">
      <SkillTestSituation ref="skillTestSituation" />
    </div>
    <ExamReport ref="examReport"></ExamReport>
    <PersonalReport ref="personalReport"></PersonalReport>
    <SkillReport ref="skillReport"></SkillReport>
  </div>
</template>

<script>
import overallExamination from '@components/manage/exam/list/OverallExamination'
import personnelTestSituation from '@components/manage/exam/list/PersonnelTestSituation'
import YTIcon from '@components/common/YTIcon'
import examReport from '@api/examReport'
import ExamReport from './ExamReport'
import SkillReport from './SkillReport'
import SkillTestSituation from './SkillTestSituation'
import PersonalReport from './PersonalReport'
export default {
  name: 'examResult',
  components: { overallExamination, YTIcon, personnelTestSituation, ExamReport, SkillTestSituation, SkillReport, PersonalReport },
  data() {
    return {
      examInfo: [
        {
          name: '考试名称:',
          key: 'examName',
          value: '农商行大数据中心考试'
        },
        {
          name: '考试简介:',
          key: 'description',
          value: '创建考试简介'
        },
        {
          name: '专业:',
          key: 'postNames',
          value: ['前端', '后端', '大后端', '偏后端']
        },
        {
          name: '考试时长:',
          key: 'limitTime',
          value: null
        },
        {
          name: '开始时间:',
          key: 'startTime',
          value: null
        },
        {
          name: '结束时间:',
          key: 'endTime',
          value: null
        },
        {
          name: '出卷方式:',
          key: 'type',
          value: null
        },
        {
          name: '通过分数:',
          key: 'passScore',
          value: null
        }
      ],
      isShowBread: false,
      loading: false,
      paperBankId: null,
      examId: null,
      nameBySearch: '',
      pathRoute: null,
      examType: null,
      userType: 0,
      external: null,
      activeName: 'first'
    }
  },
  created() {
    this.path = this.$route.path
    if (this.path !== '/manage/exam/editor') {
      let res = this.$handleParams('examNewData')
      this.paperBankId = res.paperBankId
      this.examId = res.id
      this.examBasicInfo()
    }
  },
  methods: {
    getPaperResult(examId) {
      // 设置考试 查看考试结果 接口
      this.isShowBread = true
      this.examId = examId
      this.examBasicInfo()
      this.$refs['overallRef'].setExamId(examId)
    },
    exportPdf() {
      // 导出pdf数据
      // this.loading = true
      // this.$refs['overallRef'].exportPdf()
      let payload = { departmentIds: [], examId: this.examId, tagIds: [], userIds: [] }
      this.$refs.examReport.open(payload)
    },
    exportSkillPdf() {
      //预览技能pdf
      let payload = { departmentIds: [], examId: this.examId, tagIds: [], userIds: [] }
      this.$refs.skillReport.open(payload)
    },
    showRetry() {
      // 人员考试情况 发布重考
      this.$refs['testSituation'].showRetry()
    },
    toSearch() {
      // 通过名称搜索参考人员
      this.$refs['testSituation'].getList(true)
    },
    importAllReports() {
      // 人员考试情况 导出员工报告
      if (this.$refs.testSituation.staffSelection.length === 0) {
        this.$message.warning('请先勾选要导出报告的员工')
        return
      }
      let payload = { departmentIds: [], examId: this.examId, tagIds: [], userIds: this.$refs.testSituation.staffSelection }
      this.$refs.personalReport.open(payload)
      // this.$refs['testSituation'].importAllReports()
    },
    exportSingleReport(userId) {
      let payload = { departmentIds: [], examId: this.examId, tagIds: [], userIds: [userId] }
      this.$refs.personalReport.open(payload)
    },
    examPInfo() {
      // 人员考试情况 导出员工信息
      this.$refs['testSituation'].examPInfo()
    },
    handleName(tab) {
      this.activeName = tab.name
      if (this.activeName === 'first') {
        this.$refs['overallRef'].getInfo()
      } else if (this.activeName === 'second') {
        this.$refs['testSituation'].getList()
      } else if (this.activeName === 'skill') {
        let payload = { departmentIds: [], examId: this.examId, tagIds: [], userIds: [] }
        this.$refs.skillTestSituation.getInfo(payload)
      }
    },
    examBasicInfo() {
      examReport.examBasicInfo(this.examId).then(res => {
        this.external = res.res['external']
        this.userType = res.res['userType'] // 1 - 外部人员  2 - 内部人员
        this.examType = res.res['type'] // 试卷的出卷方式
        Object.keys(res.res).forEach(r => {
          this.examInfo.forEach(e => {
            if (r === e.key) {
              e.value = res.res[r]
            }
          })
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.result-main {
  height: 100%;
  min-width: 1100px;
  .font();
  background: #f8f8f8;

  .yt-breadcrumb {
    background: #fff;
  }

  .exam-title {
    background: #fff;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    .title-info {
      width: 33%;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      padding-left: 40px;
      div {
        width: calc(100% - 60px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
      .right-span {
        margin-left: 15px;
      }
      .tags-span {
        border-radius: 16px;
        padding: 6px 8px;
        .font(12px, 500, #448bff);
        background: #edf4ff;
      }
      .ellipsis-style {
        font-size: 20px;
        color: #448bff;
      }
      ::v-deep .el-tooltip__popper {
        width: 158px !important;
        flex-flow: wrap row;
      }
    }
  }

  .tabs-style {
    justify-content: space-between;
    background: #fff;
    padding: 20px 40px 0 40px;
    ::v-deep .el-tabs__nav-wrap::after {
      background: #fff;
    }
    ::v-deep .el-tabs__header {
      margin-bottom: 0 !important;
    }
    .export-btn {
      .wh(160px, 32px);
      border-radius: 4px;
    }
    .search-and-button-style {
      .el-input-style {
        width: 234px;
      }
      .el-button--default {
        .wh(100px, 32px);
      }
      .search-btn {
        width: 80px;
        margin-left: 10px;
      }
    }
  }
}
::v-deep .el-input-group--append {
  height: 32px !important;
}
</style>
