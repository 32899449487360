<template>
  <el-dialog class="skill-report-dialog" :visible.sync="visible" top="60px" :before-close="close" @closed="closed" destroy-on-close>
    <div class="print-button back-top" @click="backTop">回到顶部</div>
    <div class="print-button" @click="exportPdf">下载报告</div>
    <div class="report-container" id="examReport">
      <div class="top">
        <img :src="require('@assets/logo/report-logo.png')" height="38px" alt="" />
        <div>
          https://enter.yuantusoft.com/home
          <img :src="require('@assets/logo/tencent-cloud-qrcode.png')" height="44px" width="44px" alt="" />
        </div>
      </div>
      <div :style="{ opacity: firstPage ? 1 : 0, height: firstPage ? 'auto' : 0 }">
        <h1 style="font-size: 36px; margin-bottom: 40px">{{ organizationName }}-{{ examInfo.postNames.value }}技能考试报告</h1>
        <div class="block">
          <p class="title">一、基本信息</p>
          <h1><span>1.1</span><span>基本信息</span></h1>
          <ul class="info">
            <li v-for="(item, key, index) in examInfo" :style="{ width: index > 4 ? '50%' : '100%' }" :key="index">
              {{ item.label }}：{{ item.value }}
            </li>
          </ul>
          <h1><span>1.2</span><span>技能下题目数量</span></h1>
          <div id="questionCountPieChart"></div>
          <h1 style="margin-top: 0"><span>1.3</span><span>技能得分情况</span></h1>
          <div id="questionPointBarChart"></div>
          <h1><span>1.4</span><span>分数分布</span></h1>
          <div ref="distributionChart" class="distributionChart"></div>
          <el-table :data="distributionList" class="yt-table">
            <el-table-column label="分数段" prop="space" align="center" />
            <el-table-column label="人数" prop="num" align="center" />
            <el-table-column label="占总人数百分比" align="center">
              <template slot-scope="scope">{{ $roundFloat(scope.row.frequency, 2) }}%</template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="block">
        <div :style="{ opacity: secondPage ? 1 : 0, height: secondPage ? 'auto' : 0 }">
          <p class="title">二、总体技能掌握情况</p>
          <h1><span>2.1</span><span>总体技能分析</span></h1>
          <div ref="skillOverallRadarChart" class="skillOverallRadarChart"></div>
          <div ref="skillOverallBarChart" class="skillOverallBarChart" :style="{ height: `${skillOverallList.length * 70 + 140}px` }"></div>
          <el-table :data="skillOverallList" class="yt-table">
            <el-table-column label="技能名称" prop="abilityName" align="center" />
            <el-table-column label="描述" prop="description" align="center" />
            <el-table-column label="平均掌握率" align="center">
              <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
            </el-table-column>
          </el-table>
          <template v-for="(item, index) in skillList">
            <div :style="{ marginTop: index === 0 ? '30px' : '80px' }" class="skill-item" :key="index">
              <h2>2.1.{{ index + 1 }} {{ item.abilityName }}详细技能掌握情况</h2>
              <div :ref="'distributionChart-' + item.id" class="distributionChart"></div>
              <h2 class="top-ten">- TOP10人员信息 -</h2>
              <el-table :data="item.topTenUsers" class="yt-table">
                <el-table-column label="姓名" prop="name" align="center" />
                <el-table-column label="电话" prop="phone" />
                <el-table-column label="掌握率">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.abilityRate, 2) }}%</template>
                </el-table-column>
                <el-table-column label="平均掌握率">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                </el-table-column>
                <el-table-column label="差异" prop="difference" align="center" />
              </el-table>
              <h2 class="top-ten" style="margin-top: 50px">- BOTTOM10人员信息 -</h2>
              <el-table :data="item.bottomTenUsers" class="yt-table">
                <el-table-column label="姓名" prop="name" align="center" />
                <el-table-column label="电话" prop="phone" />
                <el-table-column label="掌握率">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.abilityRate, 2) }}%</template>
                </el-table-column>
                <el-table-column label="平均掌握率">
                  <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                </el-table-column>
                <el-table-column label="差异" prop="difference" align="center" />
              </el-table>
            </div>
          </template>
          <h1><span>2.2</span><span>评价级别占比</span></h1>
          <div class="skill-pie-list">
            <template v-for="(item, index) in skillPieList">
              <div
                class="skill-pie-item"
                :class="{ 'has-border': (index + 1) % 2 === 0 }"
                :style="{ paddingLeft: (index + 1) % 2 === 0 ? '40px' : '0' }"
                :key="index"
              >
                <div :ref="'skillPieChart-' + item.id" class="skillPieChart"></div>
                <div class="skill-pie-title">- {{ item.abilityName }} -</div>
              </div>
            </template>
          </div>
        </div>
        <div :style="{ opacity: thirdPage ? 1 : 0, height: thirdPage ? 'auto' : 0 }">
          <template v-if="subSkillList.length > 0">
            <h1><span>2.3</span><span>详细技能分析</span></h1>
          </template>
        </div>
        <template v-for="(item, index) in subSkillList">
          <div
            :style="{ marginTop: index === 0 ? '30px' : '80px', opacity: item.pageLoading ? 1 : 0, height: item.pageLoading ? 'auto' : 0 }"
            class="skill-item"
            :key="item.id"
          >
            <h2>2.3.{{ index + 1 }} {{ item.abilityName }}详细技能掌握情况</h2>
            <template v-for="(subSkill, sIndex) in item.abilityRateVOList">
              <div class="sub-skill-item" :key="subSkill.id">
                <p>技能名称：{{ item.abilityName }}-{{ subSkill.abilityName }}</p>
                <p>技能描述：{{ subSkill.abilityDescription }}</p>
                <div :ref="'distributionChart-' + subSkill.id" class="distributionChart"></div>
                <h2 class="top-ten">- TOP10人员信息 -</h2>
                <el-table :data="subSkill.topTenUsers" class="yt-table">
                  <el-table-column label="姓名" prop="name" align="center" />
                  <el-table-column label="电话" prop="phone" />
                  <el-table-column label="掌握率">
                    <template slot-scope="scope">{{ $roundFloat(scope.row.abilityRate, 2) }}%</template>
                  </el-table-column>
                  <el-table-column label="平均掌握率">
                    <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                  </el-table-column>
                  <el-table-column label="差异" prop="difference" align="center" />
                </el-table>
                <h2 class="top-ten" style="margin-top: 50px">- BOTTOM10人员信息 -</h2>
                <el-table :data="subSkill.bottomTenUsers" class="yt-table">
                  <el-table-column label="姓名" prop="name" align="center" />
                  <el-table-column label="电话" prop="phone" />
                  <el-table-column label="掌握率">
                    <template slot-scope="scope">{{ $roundFloat(scope.row.abilityRate, 2) }}%</template>
                  </el-table-column>
                  <el-table-column label="平均掌握率">
                    <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
                  </el-table-column>
                  <el-table-column label="差异" prop="difference" align="center" />
                </el-table>
                <div v-if="sIndex < item.abilityRateVOList.length - 1" class="line"></div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <div style="font-size: 20px; color: #999999">源图数字人才管理平台</div>
      <div style="font-size: 20px; color: #999999">- 精准测评 精准培训 精准识别 精准任用 -</div>
    </div>
  </el-dialog>
</template>

<script>
import barLineChart from './reportCharts/barLineChart'
import radarChart from './reportCharts/radarChart'
import barHorizontalChart from './reportCharts/barHorizontalChart'
import pieLoopChart from './reportCharts/pieLoopChart'
import barStackChart from './reportCharts/barStackChart'
import pieChart from './reportCharts/pieChart'
// import { pdfD2, exportPdf } from '@util/pdfDown2'
import examProcessApi from '@api/examProcess'
import examResultApi from '@api/examResult'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  name: 'SkillReport',
  data() {
    return {
      visible: false,
      formData: {},
      organizationName: '',
      examInfo: {
        examName: {
          label: '测评主题',
          value: ''
        },
        description: {
          label: '测评简介',
          value: ''
        },
        postNames: {
          label: '测评专业',
          value: ''
        },
        startTime: {
          label: '开始时间',
          value: ''
        },
        endTime: {
          label: '结束时间',
          value: ''
        },
        type: {
          label: '出卷方式',
          value: ''
        },
        passScore: {
          label: '通过分数',
          value: ''
        },
        limitTime: {
          label: '测评时长',
          value: ''
        },
        candidateNum: {
          label: '参与人数',
          value: '120人'
        },
        highestScore: {
          label: '测评最高分',
          value: ''
        },
        lowestScore: {
          label: '测评最低分',
          value: ''
        },
        averageScore: {
          label: '测评平均分',
          value: ''
        },
        medianScore: {
          label: '测评中位数',
          value: ''
        }
      }, //基本信息
      questionCountPieChart: null, //题目数量饼图
      questionPointBarChart: null, //题目数量柱状图
      distributionChart: null,
      distributionList: [
        {
          point: '0-10',
          number: 10,
          percent: '0.02%'
        }
      ], //分数分布
      skillOverallRadarChart: null, //技能整体雷达图
      skillOverallBarChart: null, //技能整体柱状图
      skillOverallList: [], //技能整体表格
      skillList: [], //技能详细数据
      skillPieList: [], //技能评价级别
      subSkillList: [], //子技能详细数据
      firstPage: true,
      secondPage: true,
      thirdPage: true
    }
  },
  methods: {
    open(payload) {
      this.visible = true
      this.formData = payload
      this.$nextTick(() => {
        this.getBaseInfo()
        //题目数量饼图
        this.getQuestionNum()
        //得分情况柱状图
        this.getScore()
        //分数分布柱状图
        this.getScoreStatistics()
        //总体技能雷达图,柱状图
        this.getMainAbilityRate()
        //主技能
        this.getMainSkillScore()
        //评价级别
        this.getEvaluationLevel()
        //子技能
        this.getSubSkillScore()
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.distributionChart.clear()
      this.skillOverallRadarChart.clear()
      this.skillOverallBarChart.clear()
    },
    getBaseInfo() {
      examResultApi.getAllSimple(this.formData).then(res => {
        Object.keys(this.examInfo).forEach(item => {
          this.examInfo[item].value = res.res[item]
        })
        this.organizationName = res.res.organizationName
        this.examInfo.startTime.value = this.$formatTime(this.examInfo.startTime.value, 'yyyy-MM-dd')
        this.examInfo.endTime.value = this.$formatTime(this.examInfo.endTime.value, 'yyyy-MM-dd')
      })
    },
    getQuestionNum() {
      //答题情况饼图
      examProcessApi.getQuestionNum(this.formData).then(res => {
        //答题情况饼图
        let pieOption = JSON.parse(JSON.stringify(pieLoopChart.option))
        pieOption.series[0].data = res.res.map(item => {
          return { value: item.questionCount, name: item.abilityName }
        })
        this.questionCountPieChart = this.$echarts.init(document.getElementById('questionCountPieChart'))
        this.questionCountPieChart.setOption(pieOption)
      })
    },
    getScore() {
      //得分情况柱状图
      examProcessApi.getScore(this.formData).then(res => {
        let barOption = JSON.parse(JSON.stringify(barStackChart.option))
        barOption.xAxis.data = res.res.map(item => {
          return item.abilityName
        })
        barOption.series[0].data = res.res.map(item => {
          return item.points === 0 ? null : Math.round(item.points * 10) / 10
        })
        barOption.series[1].data = res.res.map(item => {
          return item.lostPoints === 0 ? null : Math.round(item.lostPoints * 10) / 10
        })
        this.questionPointBarChart = this.$echarts.init(document.getElementById('questionPointBarChart'))
        this.questionPointBarChart.setOption(barOption)
      })
    },
    getScoreStatistics() {
      //分数分布
      examProcessApi.getScoreStatistics(this.formData, 10).then(res => {
        this.distributionList = res.res
        let barOption = JSON.parse(JSON.stringify(barLineChart.option))
        barOption.xAxis.data = res.res.map(item => {
          return item.space
        })
        barOption.yAxis[1].axisLabel.formatter = (value, index) => {
          return this.$roundFloat(value, 0) + '%'
        }
        barOption.series[0].data = res.res.map(item => {
          return item.num
        })
        barOption.series[1].data = res.res.map(item => {
          return item.frequency
        })
        this.distributionChart = this.$echarts.init(this.$refs.distributionChart)
        this.distributionChart.setOption(barOption)
      })
    },
    getMainAbilityRate() {
      //技能整体掌握柱状图
      examProcessApi.getMainAbilityRate(this.formData).then(res => {
        this.skillOverallList = res.res
        //技能整体掌握雷达图
        let radarOption = JSON.parse(JSON.stringify(radarChart.option))
        radarOption.radar[0].indicator = res.res.map(item => {
          return { name: item.abilityName, max: 1 }
        })
        radarOption.radar[0].axisName.formatter = value => {
          return value.length > 10 ? value.slice(0, 9) + '...' : value
        }
        radarOption.series[0].data[0].value = res.res.map(item => {
          return item.averageRate
        })
        this.skillOverallRadarChart = this.$echarts.init(this.$refs.skillOverallRadarChart)
        this.skillOverallRadarChart.setOption(radarOption)
        this.$nextTick(() => {
          //技能整体掌握柱状图
          let barOption = JSON.parse(JSON.stringify(barHorizontalChart.option))
          barOption.yAxis[0].data = res.res.map(item => {
            return item.abilityName
          })
          barOption.yAxis[0].axisLabel.interval = 0
          barOption.yAxis[1].data = res.res.map(item => {
            return this.$roundFloat(item.averageRate, 2) + '%'
          })
          barOption.series[0].data = res.res.map(item => {
            return this.$roundFloat(item.averageRate, 2)
          })
          this.skillOverallBarChart = this.$echarts.init(this.$refs.skillOverallBarChart)
          this.skillOverallBarChart.setOption(barOption)
        })
      })
    },
    getMainSkillScore() {
      examProcessApi.getMainSkillScore(this.formData).then(res => {
        this.skillList = res.res.map(skill => {
          return {
            id: this.$generateUUID(),
            distributionChart: null,
            ...skill
          }
        })
        this.$nextTick(() => {
          this.skillList = this.skillList.map(skill => {
            //技能详细掌握雷达图
            let barOption = JSON.parse(JSON.stringify(barLineChart.option))
            barOption.xAxis.data = skill.statisticsVOS.map(item => {
              return item.space
            })
            barOption.yAxis[1].axisLabel.formatter = (value, index) => {
              return this.$roundFloat(value, 0) + '%'
            }
            barOption.series[0].data = skill.statisticsVOS.map(item => {
              return item.num
            })
            barOption.series[1].data = skill.statisticsVOS.map(item => {
              return item.frequency
            })
            //技能分布数据图
            skill.distributionChart = this.$echarts.init(this.$refs[`distributionChart-${skill.id}`][0])
            skill.distributionChart.setOption(barOption)
            return skill
          })
        })
      })
    },
    getEvaluationLevel() {
      //评价级别
      examProcessApi.getEvaluationLevel(this.formData).then(res => {
        this.skillPieList = res.res.map(skill => {
          return {
            id: this.$generateUUID(),
            pieChart: null,
            ...skill
          }
        })
        this.$nextTick(() => {
          this.skillPieList = this.skillPieList.map(skill => {
            //技能详细掌握雷达图
            let pieOption = JSON.parse(JSON.stringify(pieChart.option))
            pieOption.series[0].data = skill.evaluationVOS.map(item => {
              if (item.rate === 0) {
                return { value: null, name: item.evaluationName }
              } else {
                return { value: item.rate, name: item.evaluationName }
              }
            })
            //技能分布数据图
            skill.pieChart = this.$echarts.init(this.$refs[`skillPieChart-${skill.id}`][0])
            skill.pieChart.setOption(pieOption)
            return skill
          })
        })
      })
    },
    getSubSkillScore() {
      //子技能
      examProcessApi.getSubSkillScore(this.formData).then(res => {
        this.subSkillList = res.res
          .filter(skill => {
            return skill.hasOwnProperty('abilityRateVOList')
          })
          .map(skill => {
            skill.abilityRateVOList = skill.abilityRateVOList.map(item => {
              return {
                id: this.$generateUUID(),
                distributionChart: null,
                ...item
              }
            })
            return {
              id: this.$generateUUID(),
              pageLoading: true,
              ...skill
            }
          })
        this.$nextTick(() => {
          this.subSkillList = this.subSkillList.map(skill => {
            //子技能详细分析
            skill.abilityRateVOList = skill.abilityRateVOList.map(subSkill => {
              let barOption = JSON.parse(JSON.stringify(barLineChart.option))
              barOption.xAxis.data = subSkill.statisticsVOS.map(item => {
                return item.space
              })
              barOption.yAxis[1].axisLabel.formatter = (value, index) => {
                return this.$roundFloat(value, 0) + '%'
              }
              barOption.series[0].data = subSkill.statisticsVOS.map(item => {
                return item.num
              })
              barOption.series[1].data = subSkill.statisticsVOS.map(item => {
                return item.frequency
              })
              //技能分布数据图
              subSkill.distributionChart = this.$echarts.init(this.$refs[`distributionChart-${subSkill.id}`][0])
              subSkill.distributionChart.setOption(barOption)
              return subSkill
            })
            return skill
          })
        })
      })
    },
    exportPdf() {
      this.secondPage = false
      this.thirdPage = false
      this.subSkillList = this.subSkillList.map((item, skillIndex) => {
        item.pageLoading = false
        return item
      })
      this.$nextTick(() => {
        html2canvas(document.getElementById('examReport'), {
          scale: 2,
          dpi: 450,
          useCORS: true // 跨域请求
        }).then(canvas => {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          //一页pdf显示html页面生成的canvas高度;
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
          // 2为上面的scale 缩放了2倍
          let imgX = ((contentWidth + 10) / 2) * 0.75
          let imgY = (contentHeight / 2) * 0.75

          let pdfX = ((contentWidth + 10) / 2) * 0.75
          let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白

          let pdf = new JsPDF('', 'pt', [pdfX, pdfY])

          //可动态生成
          pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)

          this.firstPage = false
          this.secondPage = true
          this.$nextTick(() => {
            html2canvas(document.getElementById('examReport'), {
              scale: 2,
              dpi: 450,
              useCORS: true // 跨域请求
            }).then(canvas => {
              let contentWidth = canvas.width
              let contentHeight = canvas.height
              //一页pdf显示html页面生成的canvas高度;
              let pageData = canvas.toDataURL('image/jpeg', 1.0)
              // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
              // 2为上面的scale 缩放了2倍
              let imgX = ((contentWidth + 10) / 2) * 0.75
              let imgY = (contentHeight / 2) * 0.75

              //a4尺寸 暂时没用上
              // let imgWidth = 595.28
              // let imgHeight = (592.28 / contentWidth) * contentHeight

              let pdfX = ((contentWidth + 10) / 2) * 0.75
              let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白

              pdf.addPage([pdfX, pdfY])
              //可动态生成
              pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)

              this.secondPage = false
              this.thirdPage = true
              this.addPage(pdf, 0)
            })
          })
        })
      })
    },
    addPage(pdf, index) {
      this.subSkillList = this.subSkillList.map((item, skillIndex) => {
        item.pageLoading = skillIndex === index
        return item
      })
      this.$nextTick(() => {
        html2canvas(document.getElementById('examReport'), {
          scale: 2,
          dpi: 450,
          useCORS: true // 跨域请求
        }).then(canvas => {
          let contentWidth = canvas.width
          let contentHeight = canvas.height
          //一页pdf显示html页面生成的canvas高度;
          let pageData = canvas.toDataURL('image/jpeg', 1.0)
          // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
          // 2为上面的scale 缩放了2倍
          let imgX = ((contentWidth + 10) / 2) * 0.75
          let imgY = (contentHeight / 2) * 0.75

          //a4尺寸 暂时没用上
          // let imgWidth = 595.28
          // let imgHeight = (592.28 / contentWidth) * contentHeight
          let pdfX = ((contentWidth + 10) / 2) * 0.75
          let pdfY = (contentHeight / 2) * 0.75 // 500为地不留白
          pdf.addPage([pdfX, pdfY])

          //可动态生成
          pdf.addImage(pageData, 'JPEG', 0, 0, imgX, imgY)
          if (index === this.subSkillList.length - 1) {
            pdf.save('考试结果分析报告.pdf')
            this.firstPage = true
            this.secondPage = true
            this.thirdPage = true
            this.subSkillList = this.subSkillList.map(item => {
              item.pageLoading = true
              return item
            })
          } else {
            if (index === 0) {
              this.thirdPage = false
            }
            index++
            this.addPage(pdf, index)
          }
        })
      })
    },
    backTop() {
      document.querySelector('.skill-report-dialog').scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/reportVer1';
::v-deep .el-dialog {
  width: 1240px;
  border-radius: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
}
.print-button {
  position: fixed;
  top: 500px;
  right: 100px;
  width: 140px;
  padding: 25px 40px;
  height: 140px;
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  background: #448bff;
  box-shadow: 0 3px 6px rgba(60, 100, 209, 0.5);
  border-radius: 100px;
  cursor: pointer;
}
.back-top {
  background-color: #ff6060;
  box-shadow: 0 3px 6px rgba(255, 159, 159, 0.5);
  top: 330px;
}
</style>
