<template>
  <div class="background">
    <div style="width:100%;height: calc(100% - 10px); margin: 10px 20px 0 20px;">
      <div class="yt-content">
        <el-table class="yt-table" style="min-height: 530px;" v-loading="loading" :data="personelTestData" @selection-change="selectChange">
          <el-table-column width="31" type="selection" align="center" />
          <el-table-column v-if="$parent.userType === 0" label="学号" prop="idNumber" show-overflow-tooltip />
          <el-table-column label="姓名" prop="name" />
          <el-table-column v-if="$parent.userType === 0" label="班级" prop="department" show-overflow-tooltip />
          <el-table-column v-else label="邮箱" prop="email" />
          <el-table-column v-if="$parent.userType === 0" label="专业" prop="post" show-overflow-tooltip />
          <el-table-column v-else label="手机号" prop="phone" show-overflow-tooltip />
          <el-table-column label="考试开始时间" prop="startTime" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.startTime !== undefined">{{ $formatTime(scope.row.startTime, 'yyyy-MM-dd hh:mm:ss') }}</span>
              <span v-else>—</span>
            </template>
          </el-table-column>
          <el-table-column label="考试用时" prop="testTime" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.testTime !== undefined">{{ scope.row.testTime }}分钟</span>
              <span v-else>—</span>
            </template>
          </el-table-column>
          <el-table-column label="总分" prop="totalMark" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.totalMark }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="options" width="180">
            <template slot-scope="scope">
              <span class="tool-button" :style="scope.row.testTime === undefined ? 'cursor: no-drop' : ''" @click="handleSee(scope.row)"
                >考试详情</span
              >
              <span
                v-show="scope.row.answerPaperStatus === 6"
                class="tool-button"
                :style="scope.row.testTime === undefined ? 'cursor: no-drop' : ''"
                @click="myReport(scope.row)"
                >个人报告</span
              >
              <el-dropdown v-show="scope.row.answerPaperStatus === 6" placement="bottom-start" @command="handleCommand($event, scope.row)">
                <span class="tool-button">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="update">上传报告</el-dropdown-item>
                  <el-dropdown-item command="delete">下载报告</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :disabled="loading"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          show-elevator
          show-sizer
          show-total
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>
    <!--    上传个人报告-->
    <el-dialog class="uploadDialog" :visible.sync="IsImportExam" width="45%" title="上传个人考试报告">
      <p style="margin-bottom: 15px;">文件</p>
      <el-upload class="upload-demo" drag action="" :before-upload="uploadFile">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <Tag v-if="file" style="width: 192px" type="dot" closable color="primary" @on-close="file = null">{{ file.name }}</Tag>
      <div class="footer" style="display: flex;justify-content: flex-end;margin: 15px 0;">
        <el-button size="mini" @click="IsImportExam = false">取消</el-button>
        <el-button size="mini" :disabled="!canAble" type="primary" @click="uploadSerFile(url)">确定</el-button>
      </div>
    </el-dialog>
    <!--    发布重考-->
    <el-dialog class="deUsersModal" :visible.sync="retryModal" :close-on-click-modal="false" title="选择用户">
      <el-tabs v-model="activeIndex" style="margin-bottom: 20px;">
        <el-tab-pane label="参考人员" name="1">
          <el-table
            ref="allTable"
            :data="allData"
            style="width: 100%"
            :row-key="getRowKeys"
            border
            height="500"
            v-if="activeIndex === '1'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="未及格人员" name="2">
          <el-table
            ref="failTable"
            :data="failData"
            style="width: 100%"
            :row-key="getRowKeys"
            border
            height="500"
            v-if="activeIndex === '2'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="学号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="专业" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="未参考人员" name="3">
          <el-table
            ref="unJoinTable"
            :data="unJoinData"
            style="width: 100%"
            :row-key="getRowKeys"
            border
            height="500"
            v-if="activeIndex === '3'"
            @selection-change="handleSelect"
          >
            <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
            <el-table-column show-overflow-tooltip label="学号" align="center" prop="jobNumber" />
            <el-table-column show-overflow-tooltip label="专业" align="center" prop="postName" />
            <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="所有人员" name="4">
          <div style="height: 500px;display: flex;justify-content: space-between;">
            <div style="width: 43%;">
              <div class="container-title">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-jiagoufenxiao" />
                </svg>
                <span class="nowrap" style="margin-left: 5px;flex: 1 1 auto;">部门选择</span>
              </div>
              <div style="height: calc(100% - 50px);overflow: auto">
                <div style="text-align: left;margin: 20px 0 0 26px">
                  <!--          <span style="font-size: 14px;font-weight: 400;cursor: pointer" @click="getCurrentDepartmentId(chooseDepartmentId)">所有部门</span>-->
                </div>
                <el-tree
                  id="retry-tree-container"
                  v-if="treeData.length > 0"
                  :current-node-key="treeData[0].departmentId"
                  highlight-current
                  ref="tree"
                  node-key="departmentId"
                  @current-change="handleDepartmentId"
                  :props="defaultProps"
                  :data="treeData"
                  :expand-on-click-node="false"
                >
                  <div style="width: 100%;overflow: hidden" slot-scope="{ node, data }">
                    <el-tooltip effect="dark" :disabled="node.label.length < 8" :content="node.label" placement="bottom-start">
                      <div>{{ node.label }}</div>
                    </el-tooltip>
                  </div>
                </el-tree>
              </div>
            </div>
            <div style="width: 55%">
              <div style="display: flex;justify-content: flex-start;margin-bottom: 15px">
                <el-input
                  style="width: 300px;"
                  size="small"
                  placeholder="请输入姓名(回车搜索)"
                  v-model="retryKeyword"
                  @keyup.native.enter="search()"
                />
              </div>
              <div class="overStyle" style="max-height: 300px; overflow-y: auto">
                <el-table
                  ref="departmentTable"
                  :data="departmentTableData"
                  style="width: 100%"
                  :row-key="getRowKeys"
                  border
                  v-if="activeIndex === '4'"
                  @selection-change="handleSelect"
                >
                  <el-table-column type="selection" :reserve-selection="true" width="60" align="center" />
                  <el-table-column show-overflow-tooltip label="工号" align="center" prop="jobNumber" />
                  <el-table-column show-overflow-tooltip label="岗位" align="center" prop="postName">
                    <template slot-scope="scope">{{ scope.row.postVO.postName }}</template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip label="姓名" align="center" prop="realName" />
                </el-table>
              </div>
              <div style="margin-top: 20px;display: flex;justify-content: space-between">
                <Page size="small" show-total @on-change="handlePageNum" :current="searchUser.pageParamForm.pageNumber + 1" :total="departmentTotal">
                </Page>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-button size="mini" @click="retryExam" type="primary">确定</el-button>
      <!--        </div>-->
      <!--      </div>-->
    </el-dialog>
  </div>
</template>

<script>
import exam from '@api/exam'
import fileApi from '@api/file'
import oss from '@api/oss'
import examReport from '@api/examReport'
import util from '@util/util'
import staff from '@api/staff'
import config from '@/config/config'
export default {
  name: 'personnelTestSituation.vue',
  data() {
    return {
      total: 0,
      loading: false,
      personelTestData: [],
      pageNum: 0,
      pageSize: 10,
      failData: [],
      unJoinData: [],
      allData: [],
      treeData: [],
      defaultProps: {
        label: 'name',
        children: 'children'
      },
      IsImportExam: false,
      importIng: false,
      row: null,
      file: null,
      url: null,
      retryKeyword: '',
      retryDepartmentId: '',
      departmentTableData: [],
      userIds: [],
      departmentTotal: 0,
      retryPage: 0,
      searchUser: {
        pageParamForm: {
          pageNumber: 0,
          pageSize: 10
        },
        sortCriteria: {},
        userCriteria: {
          email: '',
          phone: '',
          realName: null,
          status: 0
        }
      },
      canAble: false,
      retryModal: false,
      activeIndex: '1',
      getRowKeys(row) {
        return row.userId
      },
      currentPage: 1,
      staffSelection: [] //个人报告用到的userId list
    }
  },
  created() {
    this.getDepartments()
  },
  methods: {
    retryExam() {
      exam.makeRepeatExam(this.$parent.examId, this.userIds).then(res => {
        if (res.res) {
          this.$overSituationMsg('发布重考成功!', 'success', this)
          if (this.activeIndex === '1') {
            this.$refs.allTable.clearSelection()
          } else if (this.activeIndex === '2') {
            this.$refs.failTable.clearSelection()
          } else if (this.activeIndex === '3') {
            this.$refs.unJoinTable.clearSelection()
          } else if (this.activeIndex === '4') {
            this.$refs.departmentTable.clearSelection()
          }
          this.retryModal = false
          this.getList()
        }
      })
    },
    handlePageNum(val) {
      this.searchUser.pageParamForm.pageNumber = val - 1
      this.retryPage = val - 1
      if (!this.retryKeyword.trim()) {
        this.searchUser.userCriteria.realName = this.retryKeyword
        staff.searchStaffs(this.searchUser, this.searchUser.pageParamForm.pageNumber, 10).then(res => {
          this.departmentTableData = res.res.data
        })
      } else {
        this.searchUser.pageParamForm.pageSize = 10
        this.searchByKeyWord()
      }
    },
    getDepartments() {
      // 所有部门
      this.treeData = []
      this.$store.dispatch('department/getDepartmentTree').then(res => {
        let tree = res.res
        this.treeData.push(tree)
        // this.retryDepartmentId = 0
        this.searchByKeyWord()
      })
    },
    search() {
      this.searchUser.pageParamForm.pageNumber = 0
      this.searchByKeyWord()
    },
    searchByKeyWord() {
      this.searchUser.departmentId = this.retryParentId === 0 ? '' : this.retryDepartmentId
      this.searchUser.keyword = this.retryKeyword
      this.searchUser.status = 0
      staff.searchStaffs(this.searchUser, this.searchUser.pageParamForm.pageNumber, this.searchUser.pageParamForm.pageSize).then(res => {
        this.departmentTableData = res.res.data
        this.departmentTotal = res.res.total
      })
    },
    handleDepartmentId(nodeData) {
      this.retryKeyword = ''
      this.retryDepartmentId = nodeData.departmentId
      this.searchByKeyWord()
    },
    handleSelect(val) {
      // 发布重考  选择
      this.userIds = []
      val.forEach(v => {
        this.userIds.push(v.userId)
      })
    },
    showRetry() {
      this.getRetryExaminerByExam()
      this.retryModal = true
    },
    getRetryExaminerByExam() {
      this.failData = []
      this.unJoinData = []
      exam.getExamineesByExamId(this.$parent.examId, 0, 9999, '', this.$parent.nameBySearch).then(res => {
        this.allData = res.res.data
        this.allData.forEach(item => {
          if (item.hasOwnProperty('pass') && !item.pass) {
            this.failData.push(item)
          }
          if (!item.hasOwnProperty('answerPaperId')) {
            this.unJoinData.push(item)
          }
        })
      })
    },
    async examPInfo() {
      // 导出员工信息
      // userType 来区分接口 0 内部 1 外部
      this.$parent.loading = true
      if (this.$parent.userType === 0) {
        const res = await exam.exportPeoInfo(this.$parent.examId)
        this.exportInterFace(res, '内部考试人员')
      } else {
        window.open(`${config.baseUrl}/user/api/v1/department/school/export?departmentId=${this.$parent.examId}&api_key=school`)
      }
    },
    exportInterFace(data, tip) {
      util.exportExamPeoInfo(data, tip)
      this.$parent.loading = false
    },
    importAllReports() {
      // 导出员工报告
      this.$parent.loading = true
      examReport
        .getExamReportById(this.$parent.examId)
        .then(res => {
          if (res.res === '该考试无有效报告，无法生成') {
            return
          }
          // 创建隐藏的可下载链接
          let eleLink = document.createElement('a')
          eleLink.download = res.res.substring(res.res.lastIndexOf('/') + 1)
          eleLink.style.display = 'none'
          // 字符内容转变成blob地址
          eleLink.href = res.res
          // 触发点击
          document.body.appendChild(eleLink)
          eleLink.click()
          // 然后移除
          document.body.removeChild(eleLink)
        })
        .catch(() => {})
        .finally(() => {
          this.$parent.loading = false
        })
    },
    handleSee(row) {
      if (row.testTime === undefined) return
      // if (row.answerPaperStatus !== 6) return
      let res = this.$handleParams('examNewData')
      this.$router.push({
        name: 'newExamDetail',
        // name: 'examDetail',
        params: {
          tabLabel: this.$route.query.tabLabel,
          paperBankId: res.paperBankId,
          id: res.id,
          examId: this.$parent.examId,
          submitTime: row.submitTime,
          duration: row.duration,
          score: row.totalMark,
          userId: row.userId,
          exam: true,
          name: res.name,
          IsAnswerExam: true,
          IsExamLibrary: true
        }
      })
    },
    selectChange(val) {
      this.staffSelection = val.map(item => {
        return item.userId
      })
    },
    myReport(row) {
      // 个人报告
      if (row.testTime === undefined) return
      this.$emit('exportSingleReport', row.userId)
      // this.$router.push({
      //   name: 'myReport',
      //   params: {
      //     userId: row.userId,
      //     id: this.$parent.examId,
      //     anPaId: row.answerPaperId,
      //     examName: this.$route.query['name'],
      //     tabLabel: this.$route.query.tabLabel,
      //     page: this.pageNum // 缓存需要
      //   }
      // })
    },
    getList(bool) {
      // bool = true 说明是搜索 需要吧页码改为0
      if (bool) this.pageNum = 0
      this.loading = this.$parent.loading = true
      this.personelTestData = []
      exam
        .getExamineesByExamId(this.$parent.examId, this.pageNum, this.pageSize, '', this.$parent.nameBySearch)
        .then(res => {
          this.total = res.res.total
          res.res.data.forEach(r => {
            this.personelTestData.push({
              idNumber: r['jobNumber'],
              userId: r['userId'],
              answerPaperStatus: r['answerPaperStatus'],
              answerPaperId: r['answerPaperId'],
              name: r['realName'],
              department: r['departmentName'],
              email: r['email'],
              phone: r['phone'],
              post: r['postName'],
              submitTime: r['submitTime'],
              startTime: r['startTime'],
              testTime: r['duration'] !== undefined ? r['duration'] : undefined,
              totalMark: r.hasOwnProperty('score') && r.score !== null && r.score !== undefined ? r['score'] : '-',
              options: null
            })
          })
        })
        .finally(() => {
          this.loading = this.$parent.loading = false
        })
    },
    handleCommand(e, row) {
      if (e === 'delete') {
        // 下载个人报告
        let form = {
          examId: Number(this.$parent.examId),
          jobNumber: row.idNumber || null, // 工号
          reportType: 1, // 0 =》 试卷报告 1=》 个人报告
          userId: row.userId
        }
        exam.getExamReport(form).then(res => {
          if (res.res === '') {
            return this.$overSituationMsg('未导入报告或链接已失效', 'warning', this)
          }
          window.open(res.res)
          // window.location.href = res.res
        })
      } else {
        //  上传个人报告
        this.row = row
        this.IsImportExam = true
      }
    },
    uploadFile(file) {
      let _this = this
      this.file = file
      if (window.uploadUrl) {
        // 局域网
        let formData = new FormData()
        formData.append('file', file)
        fileApi.uploadServeFile(formData).then(res => {
          if (res.code === 0) {
            this.url = res.res
            this.canAble = true
          }
        })
      } else {
        // oss.
        oss.upType().then(res => {
          if (res.code === 0) {
            oss.getQuestionFileToken(file.name).then(data => {
              let fData = data.res
              const formData = new FormData()
              formData.append('key', fData.dir)
              formData.append('OSSAccessKeyId', fData.accessId)
              formData.append('policy', fData.policy)
              formData.append('Signature', fData.signature)
              formData.append('file', file)
              fetch(`https://${fData.host}`, {
                method: 'POST',
                body: formData
              }).then(() => {
                _this.url = `https://${fData.host}/${fData.dir}`
                _this.canAble = true
              })
            })
          }
        })
      }
      return false
    },
    uploadSerFile(file) {
      let form = {
        answerPaperId: this.row.answerPaperId,
        examId: this.$parent.examId,
        jobNumber: this.row.jobNumber || null, // 工号
        reportType: 1, // 0 =》 试卷报告 1=》 个人报告
        reportUrl: file,
        userId: this.row.userId
      }
      exam.importExam(form).then(res => {
        if (res.code === 0) {
          this.$overSituationMsg('成功', 'success', this)
          this.IsImportExam = false
          this.getList()
        }
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.getList()
    },
    changePageSize(pageSize) {
      this.pageNum = 0
      this.pageSize = pageSize
      this.getList()
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../../theme/variables';
.background {
  margin: 20px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  opacity: 1;
  display: flex;
}
.tool-button {
  margin-right: 10px;
  font-size: 12px;
  color: #448bff;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #2276ff;
  }
  &:last-child {
    margin-right: 0;
  }
}
.pagination {
  .flexStyle(flex, center, center);
}
</style>
