<template>
  <div class="overall-main">
    <!--  基本信息  -->
    <div class="yt-flex-layout">
      <div class="card-style c1" :style="examType !== '随机出题' ? '' : 'margin-right: 0'">
        <b>基本信息</b>
        <div class="c1-basic-style yt-flex-layout" v-for="(basic, bIndex) in basicInfo" :key="bIndex">
          <div class="yt-flex-layout flexCenter">
            <div class="dolt" :style="{ background: basic.color }" />
            <span>{{ basic.name }}</span>
          </div>
          <div>{{ basic.value }}{{ basic.key.includes('Score') ? '分' : null }}</div>
        </div>
      </div>
      <div v-if="examType !== '随机出题'" class="card-style c2" v-loading="typeLoading" element-loading-text="加载中">
        <b
          >题型分布 <span>(总分: {{ totalPoints }} 分)</span></b
        >
        <div id="questionType" />
      </div>
      <div class="card-style c3" v-loading="pointsLoading" element-loading-text="加载中">
        <b>分数分布</b>
        <div id="pointDistribution" />
      </div>
    </div>

    <!--  技能掌握情况  -->
    <!--    <div class="ability-desc" v-loading="abilityLoading" element-loading-text="加载中">-->
    <!--      <div class="yt-flex-layout title-desc" style="justify-content: space-between">-->
    <!--        <b>试卷技能掌握情况</b>-->
    <!--        <div class="yt-flex-layout flexCenter op-style" @click="showAbility = !showAbility">-->
    <!--          <span>{{ showAbility ? '收起' : '展开' }}</span>-->
    <!--          <YTIcon @click="showAbility = !showAbility" :class="['icon', showAbility ? 'active' : '']" :href="'#icon-open'" />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div v-show="showAbility" class="showOrHide">-->
    <!--        <div class="yt-flex-layout">-->
    <!--          <el-table @cell-mouse-enter="handleHoverCell" @cell-mouse-leave="handleLeaveCell" class="yt-table" :data="abilityData">-->
    <!--            <el-table-column label="技能序号" width="80" align="center">-->
    <!--              <template slot-scope="scope">{{ scope.$index + 1 }}</template>-->
    <!--            </el-table-column>-->
    <!--            <el-table-column label="技能" width="145" prop="ability" />-->
    <!--            <el-table-column label="主题" width="145" prop="theme" />-->
    <!--            &lt;!&ndash;            <el-table-column label="历史平均率" align="right" width="150">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <template slot-scope="scope">{{ scope.row.historyRate }}%</template>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </el-table-column>&ndash;&gt;-->
    <!--            <el-table-column label="本次测评平均掌握率" align="right" width="150">-->
    <!--              <template slot-scope="scope">{{ (scope.row.testRate || 0) * 100 }}%</template>-->
    <!--            </el-table-column>-->
    <!--            <el-table-column label="题目数量" width="100" align="right" prop="count" />-->
    <!--          </el-table>-->
    <!--          <div id="abilityEcharts" :style="{ minHeight: abilityTableHeight || 0 }" />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--  考试答题情况  -->
    <div class="ability-desc" v-show="answerData.length > 0">
      <div class="yt-flex-layout title-desc" style="justify-content: space-between">
        <b>本场考试答题情况</b>
        <div class="yt-flex-layout flexCenter op-style" @click="showAnswer = !showAnswer">
          <span>{{ showAnswer ? '收起' : '展开' }}</span>
          <YTIcon @click="showAnswer = !showAnswer" :class="['icon', showAnswer ? 'active' : '']" :href="'#icon-open'" />
        </div>
      </div>
      <div v-show="showAnswer" class="showOrHide">
        <el-table :data="answerData" class="yt-table" width="100%">
          <el-table-column label="题目序号" width="85" align="center">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="题型" min-width="120" prop="questionType" />
          <el-table-column label="难度" min-width="120" prop="difficulty" />
          <el-table-column label="技能" min-width="165" prop="abilityNames">
            <template slot-scope="scope">
              <span style="word-break: keep-all">{{ scope.row.abilityNames }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column label="时长" min-width="135" prop="time" />-->
          <!--          <el-table-column label="历史平均率" min-width="150">-->
          <!--            <template slot-scope="scope">{{ scope.row.historyRate }}%</template>-->
          <!--          </el-table-column>-->
          <el-table-column label="本次测评平均掌握率" min-width="150">
            <template slot-scope="scope">{{ scope.row.testRate === 1 ? '100' : Math.round((scope.row.testRate || 0) * 100) }}%</template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!--  考试评价  -->
    <div class="ability-desc" style="padding-bottom: 20px">
      <div class="yt-flex-layout title-desc" style="justify-content: space-between">
        <b>本次考试评价 <YTIcon @click="isEvaluate = !isEvaluate" :href="'#icon-zuoti'" style="fill: #FFBB00"/></b>
      </div>
      <el-input
        placeholder="请为本场考试做个评价:"
        v-show="isEvaluate"
        type="textarea"
        size="small"
        :autosize="{ minRows: 4 }"
        style="padding: 0 30px"
        :maxlength="300"
        @blur="submitComment"
        v-model.trim="evaluate"
      />
      <div v-show="!isEvaluate" v-html="evaluate" style="text-align: left;padding: 0 30px" />
    </div>
  </div>
</template>

<script>
import { questionTypeOption, pointDistribution, abilityOption } from './overallEcharts'
import YTIcon from '@components/common/YTIcon'
import examReport from '@api/examReport'
import statisticApi from '@api/statistic'
import { pdfD2 } from '@util/pdfDown2'
export default {
  name: 'overallExamination',
  components: { YTIcon },
  props: ['examType'],
  data() {
    return {
      basicInfo: [
        {
          color: '#448BFF',
          name: '考试总人数',
          key: 'peopleNum',
          value: null
        },
        {
          color: '#FF5050',
          name: '最高分',
          key: 'maxScore',
          value: null
        },
        {
          color: '#FFBB00',
          name: '最低分',
          key: 'minScore',
          value: null
        },
        {
          color: '#4CE47A',
          name: '平均分',
          key: 'avgScore',
          value: null
        }
      ],
      questionTypeData: [],
      totalPoints: 0,
      abilityData: [],
      answerData: [],
      qType: ['判断题', '单选题', '多选题', '填空题', '简答题', '代码题'],
      examId: null,
      showAbility: true,
      showAnswer: true,
      isEvaluate: false,
      typeLoading: false, // 题型加载中
      pointsLoading: false, // 分数加载中
      abilityLoading: false, // 技能加载中
      evaluate: null,
      abilityTableHeight: null,
      myChart1: null,
      myChart2: null,
      timer1: null,
      myChart3: null
    }
  },
  created() {
    if (this.$parent.path !== '/manage/exam/editor') {
      let res = this.$handleParams('examNewData')
      this.examId = res.id
      this.getInfo()
    }
    this.loadingEle()
  },
  destroyed() {
    clearTimeout(this.timer1)
  },
  mounted() {
    window.addEventListener(
      'resize',
      () => {
        if (this.myChart1) {
          this.myChart1.resize()
        }
        if (this.myChart2) {
          this.myChart2.resize()
        }
        if (this.myChart3) {
          this.myChart3.resize()
        }
      },
      false
    )
  },
  methods: {
    loadingEle() {
      // 加载echarts
      this.typeLoading = true
      this.pointsLoading = true
      this.abilityLoading = true
    },
    setExamId(examId) {
      this.loadingEle()
      this.examId = examId
      this.getInfo()
    },
    exportPdf() {
      pdfD2(document.getElementById('examReport'), '考试结果分析报告.pdf')
        .then(() => {
          this.$overSituationMsg('导出成功!', 'success', this)
        })
        .catch(() => {
          this.$overSituationMsg('导出失败!', 'error', this)
        })
        .finally(() => {
          this.$parent.loading = false
        })
    },
    submitComment() {
      let form = {
        comment: this.evaluate,
        examId: this.examId,
        order: 4
      }
      examReport.saveEvaByExamReport(form).then(res => {
        if (res.code === 0 && res.res) {
          this.$overSituationMsg('本场考试评价录入成功', 'success', this)
          this.isEvaluate = false
          this.evaluate = res.res
        }
      })
    },
    getInfo() {
      this.$parent.loading = true
      this.examInfo()
      this.answerExamInfo()
      this.examCommond()
      // this.examIntervalDistributionInfo()
    },
    examAbilityInfo() {
      // 试卷技能掌握情况
      statisticApi.examResultAbility(this.examId).then(res => {
        // this.abilityData = []
        this.abilityData = res.res.map(r => {
          return {
            id: r['knowledgePointId'],
            ability: r['knowledgeName'],
            theme: r['categoryName'],
            testRate: r['averageRate'],
            count: r['questionCount']
          }
        })
        this.timer1 = setTimeout(() => {
          let tableClass = document.getElementsByClassName('yt-table')
          if (tableClass.length > 0) {
            this.abilityTableHeight = tableClass[0].clientHeight || 0
          }
          this.initQuestionTypeEcharts()
        }, 1500)
      })
    },
    examCommond() {
      // 获取本场考试评价
      // 请使用接口数据order=4
      examReport.getEvaByExamReport(this.examId).then(res => {
        res.res.forEach(r => {
          if (r.order === 4) {
            this.evaluate = r.comment
          }
        })
      })
    },
    examInfo() {
      // 模拟试卷2020的信息
      // examReportApi
      let examId = this.examId
      examReport.getNowExamInfo(examId).then(res => {
        // 获取分数 人数 信息
        this.totalPoints = res.res.examTotalScore || 0
        Object.keys(res.res).forEach(r => {
          this.basicInfo.forEach(b => {
            if (r === b['key']) {
              b['value'] = res.res[r]
            }
          })
        })
        // 获取题型比例信息
        let questionInfo = res.res['sectionInfo'] || []
        this.questionTypeData = []
        questionInfo.forEach(q => {
          this.questionTypeData.push({
            name: q['sectionName'],
            value: Number(q['percentage'].substr(0, q['percentage'].indexOf('%')))
          })
        })
        this.examIntervalDistributionInfo()
      })
    },
    answerExamInfo() {
      // 本场考试大题情况列表信息
      // 模拟id2020
      let examId = this.examId
      let difficult = ['', '', '', '简单', '', '中等', '', '困难']
      this.answerData = []
      examReport.getNewQuestions(examId).then(res => {
        this.answerData = res.res.map(r => {
          return {
            questionType: this.qType[r['quesType']],
            testRate: r['rate'],
            difficulty: difficult[r['difficulty']],
            historyRate: '5.55',
            abilityNames: r['abilityNames'] ? r['abilityNames'].toString() : '暂无'
          }
        })
      })
    },
    examIntervalDistributionInfo() {
      // 考试分数分布
      // 接口数据请取[statisticsVOS]数据
      // 模拟id 2020
      let examId = this.examId
      pointDistribution.xAxis[0].data = []
      pointDistribution.series.forEach(s => {
        s.data = []
      })
      examReport.getExamPointsByPeoNum(examId).then(res => {
        let vos = res.res['statisticsVOS'] ? res.res['statisticsVOS'] : []
        vos.forEach(v => {
          pointDistribution.xAxis[0].data.push(v['space'])
          pointDistribution.series.forEach(s => {
            if (s.name === '人数') {
              s.data.push(v['num'])
            } else {
              s.data.push(v['frequency'] * 100)
            }
          })
        })
        this.examAbilityInfo()
      })
    },
    handleLeaveCell() {
      abilityOption.series[0].data = []
      this.abilityData.forEach(a => {
        abilityOption.series[0].data.push(a.count)
      })
      abilityOption.series[0].data.reverse()
      this.myChart3.setOption(abilityOption)
      this.myChart3.resize()
    },
    handleHoverCell(row) {
      abilityOption.series[0].data = []
      let find = this.abilityData.findIndex(item => item.id === row.id)
      this.abilityData.forEach(a => {
        abilityOption.series[0].data.push(a.count)
      })
      abilityOption.series[0].data[find] = {
        value: row.count,
        itemStyle: { color: '#448BFF' }
      }
      abilityOption.series[0].data.reverse()
      this.myChart3.setOption(abilityOption)
      this.myChart3.resize()
    },
    initQuestionTypeEcharts() {
      // 考试题型
      this.typeLoading = true
      questionTypeOption.series[0].data = []
      questionTypeOption.series[0].data = this.questionTypeData
      abilityOption.yAxis.data = []
      abilityOption.series[0] = {
        name: '技能掌握情况',
        type: 'bar',
        data: []
      }
      this.abilityData.forEach(a => {
        abilityOption.yAxis.data.push(a.ability)
        abilityOption.series[0].data.push(a.count)
      })
      abilityOption.yAxis.data.reverse()
      abilityOption.series[0].data.reverse()
      let chartDom1 = document.getElementById('pointDistribution')
      // let chartDom2 = document.getElementById('abilityEcharts')
      this.myChart2 = this.$echarts.init(chartDom1)
      // this.myChart3 = this.$echarts.init(chartDom2)
      this.myChart2.setOption(pointDistribution)
      // this.myChart3.setOption(abilityOption)
      if (this.examType !== '随机出题') {
        let chartDom = document.getElementById('questionType')
        this.myChart1 = this.$echarts.init(chartDom)
        this.myChart1.setOption(questionTypeOption)
      }
      this.$parent.loading = false
      this.pointsLoading = false
      this.abilityLoading = false
      this.typeLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.overall-main {
  /*width: 98.3%;*/
  margin: 30px 10px 0 10px;
  .card-style {
    padding-top: 10px;
    text-align: left;
    .font(14px, 500, #333);
    b {
      padding-left: 10px;
      span {
        .font(11px, 500, #888);
      }
    }
    height: 300px;
    background: #ffffff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  }
  .c1 {
    width: 23.3%;
    margin-right: 10px;
    min-width: 345px;
    .c1-basic-style {
      font-size: 12px;
      margin-top: 36px;
      padding: 0 18px;
      justify-content: space-between;
    }
    .dolt {
      margin-right: 7px;
      .wh(4px, 4px);
      border-radius: 50%;
    }
  }
  .c2 {
    /*margin: 0 10px;*/
    width: 32.5%;
    #questionType {
      .wh(100%, calc(100% - 30px));
    }
  }
  .c3 {
    flex: 1;
    margin-left: 10px;
    #pointDistribution {
      .wh(100%, calc(100% - 30px));
    }
  }
  .none-style {
    display: none;
  }
  .ability-desc {
    margin-bottom: 20px;
    .font(14px, 500, #333);
    padding: 10px;
    background: #fff;
    margin-top: 20px;
    min-height: 75px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
    .title-desc {
      margin-bottom: 20px;
      b {
        font-weight: 500;
      }
      span {
        font-size: 12px;
        color: #888;
      }
      .op-style {
        cursor: pointer;
        .wh(53px, 25px);
        border: 1px solid #e6e6e6;
        border-radius: 13px;
        background: #f7f7f7;
        &:hover {
          background: #f0f0f0;
        }
      }
    }
    .showOrHide {
      #abilityEcharts {
        margin-left: 20px;
        min-width: 800px;
      }
    }
  }
}
.active {
  position: relative;
  top: 6px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
::v-deep .circle-style {
  .wh(14px, 14px);
  border-radius: 50%;
}
</style>
