import config from '../config/config'
import { axios } from './index'
import course from '@api/course'

const courseStatisticUrl = config.baseUrl + '/course/api/v1/statistic'
const examStatisticUrl = config.baseUrl + '/exam/api/v1/statistic'
const userStatisticUrl = config.baseUrl + '/user/api/v1/statistic'
const questionStatisticUrl = config.baseUrl + '/question/api/v1/statistics'
export default {
  //获取部门人员学习任务信息
  getDepartmentStudyInfo(payload, pageNum, pageSize) {
    return axios.get(
      `${courseStatisticUrl}/department/study-info?departmentId=${payload.departmentId}&courseId=${payload.courseId}&pageNumber=${pageNum}&pageSize=${pageSize}`
    )
  },
  //导出部门学习任务信息
  exportDepartmentStudyInfo(payload) {
    return axios.get(`${courseStatisticUrl}/export/department/study-info?departmentId=${payload.departmentId}&courseId=${payload.courseId}`, {
      responseType: 'blob'
    })
  },
  //获取部门学习任务课程
  getDepartmentCourse(payload) {
    return axios.get(`${courseStatisticUrl}/department/courses?departmentId=${payload}`)
  },
  //获取部门人员学习任务信息
  getDepartmentStaffStudyInfo(payload, pageNum, pageSize) {
    return axios.post(`${courseStatisticUrl}/department/staff/study-info?pageNumber=${pageNum}&pageSize=${pageSize}`, payload)
  },
  //获取部门人员学习任务课程
  exportDepartmentStaffStudyInfo(payload) {
    return axios.post(`${courseStatisticUrl}/export/department/staff/study-info`, payload, {
      responseType: 'blob'
    })
  },
  //获取部门考试
  getDepartmentExamInfo(payload, pageNum, pageSize) {
    return axios.get(
      `${examStatisticUrl}/department/exam?departmentId=${payload.departmentId}&examId=${payload.examId}&page=${pageNum}&size=${pageSize}`
    )
  },
  //导出部门考试
  exportDepartmentExam(payload) {
    return axios.get(`${examStatisticUrl}/export/department/exam?departmentId=${payload.departmentId}&examId=${payload.examId}`, {
      responseType: 'blob'
    })
  },
  //获取部门人员考试
  getDepartmentUserExam(payload, pageNum, pageSize) {
    return axios.post(
      `${examStatisticUrl}/department/user/exam?departmentId=${payload.departmentId}&examId=${payload.examId}&sort=${payload.sort}&page=${pageNum}&size=${pageSize}`,
      payload.userIds
    )
  },
  //导出部门人员考试
  exportDepartmentUserExam(payload) {
    return axios.get(
      `${examStatisticUrl}/export/user/department/exam?departmentId=${payload.departmentId}&examId=${payload.examId}&userIds=${payload.userIds}`,
      {
        responseType: 'blob'
      }
    )
  },
  //获取部门考试名称
  getDepartmentExam(payload) {
    return axios.get(`${examStatisticUrl}/department/exam/name?departmentId=${payload}`)
  },
  //获取部门考试人员
  getDepartmentExamUser(payload) {
    return axios.get(`${examStatisticUrl}/department/user/name?departmentId=${payload}`)
  },
  //获取部门学习任务人员
  getDepartmentStudyTaskUser(payload) {
    return axios.get(`${courseStatisticUrl}/department/user/name?departmentId=${payload}`)
  },
  //课程分析
  getCourseA(p, s, k) {
    return axios.get(`${courseStatisticUrl}/study/course/list?pageNumber=${p}&pageSize=${s}&content=${k}`)
  },
  //考试分析
  getExamA(p, s, k, t) {
    return axios.get(`${examStatisticUrl}/exam/list?page=${p}&size=${s}&examName=${k}&type=${t}`)
  },
  //题目分析
  getQuestionA(p, s, k) {
    return axios.get(`${questionStatisticUrl}/all/questions?page=${p}&size=${s}&questionName=${k}`)
  },
  // 考试结果 技能掌握情况
  examResultAbility(examId) {
    return axios.get(`${questionStatisticUrl}/exam/user/knowledge/rate?examId=${examId}`)
  },
  //导出人才画像数据
  downloadTalentPortrait() {
    // return `${userStatisticUrl}/download/talent-portrait-data`
    return axios.get(`${userStatisticUrl}/download/talent-portrait-data`, {
      responseType: 'blob'
    })
  },
  //导入人才画像数据
  uploadTalentPortrait(payload) {
    return axios.post(`${userStatisticUrl}/upload/talent-portrait-data`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async getMonthUploadStatistic(beginDate, endDate) {
    const requestList = [
      _setDefaultOnError(
        'course',
        axios.get(`${config.baseUrl}/course/api/v1/statistic/course/sum/time?timeStampStart=${beginDate}&timeStampEnd=${endDate}`)
      ),
      _setDefaultOnError(
        'question',
        axios.get(`${config.baseUrl}/question/api/v1/statistics/question/number?timeStampStart=${beginDate}&timeStampEnd=${endDate}`)
      ),
      _setDefaultOnError('datum', axios.get(`${config.baseUrl}/datum`)),
      _setDefaultOnError(
        'paper',
        axios.get(`${config.baseUrl}/paper/api/v1/statistic/paper/number?timeStampStart=${beginDate}&timeStampEnd=${endDate}`)
      )
    ]
    return Promise.all(requestList).then(data => {
      const ret = {}
      data.forEach(item => {
        ret[item.name] = item.value
      })
      return ret
    })
  }
}

function _setDefaultOnError(name, AxiosPromise) {
  return new Promise((resolve, reject) => {
    AxiosPromise.then(res => resolve({ name, value: res.res })).catch(err => resolve({ name, value: 0 }))
  })
}
