<template>
  <div class="result-container">
    <div class="top">
      <div class="block" style="min-width: 280px;">
        <p>基本信息</p>
        <ul style="margin-top: 8px">
          <template v-for="(item, index) in baseInfo">
            <li>
              <div class="label">
                <span class="dot" :style="{ backgroundColor: item.color }"></span>
                {{ item.label }}
              </div>
              <div style="margin-right: 5px">{{ item.value }}</div>
            </li>
          </template>
        </ul>
      </div>
      <div class="block pie-container">
        <p>技能下题目数量</p>
        <div class="chart" ref="skillQuestionCountChart"></div>
      </div>
      <div class="block">
        <p>技能得分情况</p>
        <div class="chart" ref="skillScoreChart"></div>
      </div>
    </div>
    <div class="block" style="margin-top: 10px">
      <p>技能分布情况</p>
      <h4 style="margin-top: 20px; margin-left: 10px; font-size: 14px">总体技能分析</h4>
      <div class="analysis-container">
        <div class="item has-border" style="height: 194px" ref="skillAnalysisRadarChart"></div>
        <div class="item has-border" style="width: calc(33% + 48px); padding: 0 24px; flex-shrink: 0">
          <el-table :data="skillAnalysisList" class="yt-table">
            <el-table-column label="技能名称" prop="abilityName" show-overflow-tooltip />
            <el-table-column label="描述" prop="description" show-overflow-tooltip />
            <el-table-column label="平均掌握率" show-overflow-tooltip>
              <template slot-scope="scope">{{ $roundFloat(scope.row.averageRate, 2) }}%</template>
            </el-table-column>
            <el-table-column label="题目数量" prop="questionCount" show-overflow-tooltip />
          </el-table>
        </div>
        <div class="item" :style="{ height: `${33 + skillAnalysisList.length * 50}px` }" ref="skillOverallBarChart"></div>
      </div>
      <!--      <template v-for="(skill, index) in skillList">-->
      <!--        <div :key="skill.id" class="skill-block-wrapper">-->
      <!--          <div class="skill-block">-->
      <!--            <div>{{ skill.abilityName }}</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </template>-->
    </div>
    <!--    <div class="block" style="margin-top: 10px">-->
    <!--      <p>评价级别占比</p>-->
    <!--      <div class="skill-pie-list">-->
    <!--        <template v-for="(item, index) in skillPieList">-->
    <!--          <div-->
    <!--            class="skill-pie-item"-->
    <!--            :class="{ 'has-border': (index + 1) % 2 === 0 }"-->
    <!--            :style="{ paddingLeft: (index + 1) % 2 === 0 ? '40px' : '0' }"-->
    <!--            :key="index"-->
    <!--          >-->
    <!--            <div :ref="'skillPieChart-' + item.id" class="skillPieChart"></div>-->
    <!--            <div class="skill-pie-title">- {{ item.abilityName }} -</div>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import barLineChart from './reportCharts/barLineChart'
import radarChart from './reportCharts/radarChart'
import barHorizontalChart from './reportCharts/barHorizontalChart'
import pieLoopChart from './reportCharts/pieLoopChart'
import barStackChart from './reportCharts/barStackChart'
import pieChart from './reportCharts/pieChart'
import { pdfD2 } from '@util/pdfDown2'
import examProcessApi from '@api/examProcess'
import examResultApi from '@api/examResult'

export default {
  name: 'SkillReport',
  data() {
    return {
      formData: {},
      baseInfo: [
        {
          key: 'candidateNum',
          label: '考试总人数',
          color: '#448BFF',
          value: ''
        },
        {
          key: 'highestScore',
          label: '最高分',
          color: '#FF5050',
          value: ''
        },
        {
          key: 'lowestScore',
          label: '最低分',
          color: '#FFBB00',
          value: ''
        },
        {
          key: 'averageScore',
          label: '平均分',
          color: '#4CE47A',
          value: ''
        },
        {
          key: 'medianScore',
          label: '中位数',
          color: '#FFDB7A',
          value: ''
        }
      ],
      skillQuestionCountChart: null, //题目数量饼图
      skillScoreChart: null, //技能得分情况柱状图
      skillAnalysisRadarChart: null, //总体技能分析雷达图
      skillAnalysisList: [],
      skillOverallBarChart: null, //总体技能分析柱状图
      skillList: [], //主技能掌握情况
      skillPieList: [] //技能评价级别
    }
  },
  methods: {
    getInfo(payload) {
      this.formData = payload
      this.$nextTick(() => {
        this.getBaseInfo()
        this.getQuestionNum()
        this.getScore()
        this.getMainAbilityRate()
        // this.getMainSkillScore()
        //评价级别
        // this.getEvaluationLevel()
      })
    },
    getBaseInfo() {
      examResultApi.getAllSimple(this.formData).then(res => {
        this.baseInfo = this.baseInfo.map(item => {
          item.value = res.res[item.key]
          return item
        })
      })
    },
    getQuestionNum() {
      //答题情况饼图
      examProcessApi.getQuestionNum(this.formData).then(res => {
        //答题情况饼图
        let pieOption = JSON.parse(JSON.stringify(pieLoopChart.option))
        pieOption.series[0].center = ['50%', '50%']
        pieOption.series[0].radius = [52, 70]
        pieOption.series[0].label.fontSize = '12px'
        pieOption.series[0].label.width = 90
        pieOption.series[0].labelLine.length2 = 30
        pieOption.series[0].data = res.res.map(item => {
          return { value: item.questionCount, name: item.abilityName }
        })
        this.skillQuestionCountChart = this.$echarts.init(this.$refs.skillQuestionCountChart)
        this.skillQuestionCountChart.setOption(pieOption)
      })
    },
    getScore() {
      //得分情况柱状图
      examProcessApi.getScore(this.formData).then(res => {
        let barOption = JSON.parse(JSON.stringify(barStackChart.option))
        barOption.tooltip = {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        }
        barOption.legend = {
          data: ['得分', '失分'],
          top: 0,
          right: 0,
          itemWidth: 16,
          itemHeight: 8,
          itemGap: 20,
          textStyle: {
            fontSize: '14px'
          }
        }
        barOption.grid = { top: 8, bottom: 21, left: 27, right: 7 }
        barOption.dataZoom = [{ type: 'inside' }]
        barOption.xAxis.axisLabel.fontSize = 12
        barOption.xAxis.axisLabel.width = 130
        barOption.xAxis.axisLabel.overflow = 'truncate'
        barOption.xAxis.data = res.res.map(item => {
          return item.abilityName
        })
        barOption.yAxis.axisLabel.fontSize = 12
        barOption.series[0].label.fontSize = 12
        barOption.series[0].data = res.res.map(item => {
          return item.points === 0 ? null : item.points
        })
        delete barOption.series[0].barWidth
        barOption.series[1].label.fontSize = 12
        barOption.series[1].data = res.res.map(item => {
          return item.lostPoints === 0 ? null : item.lostPoints
        })
        delete barOption.series[1].barWidth
        this.skillScoreChart = this.$echarts.init(this.$refs.skillScoreChart)
        this.skillScoreChart.setOption(barOption)
      })
    },
    getMainAbilityRate() {
      //技能整体掌握柱状图
      examProcessApi.getMainAbilityRate(this.formData).then(res => {
        this.skillAnalysisList = res.res
        //技能整体掌握雷达图
        let radarOption = JSON.parse(JSON.stringify(radarChart.option))
        radarOption.legend.right = 23
        radarOption.legend.top = 0
        radarOption.legend.itemWidth = 6
        radarOption.legend.itemHeight = 6
        radarOption.legend.textStyle.fontSize = '12px'
        radarOption.radar[0].indicator = res.res.map(item => {
          return {
            name: item.abilityName,
            max: 1
          }
        })
        radarOption.radar[0].center = [149, 109]
        radarOption.radar[0].radius = 79
        radarOption.radar[0].axisNameGap = 5
        radarOption.radar[0].axisName.fontSize = '12px'
        radarOption.radar[0].axisName.formatter = value => {
          return value.length > 10 ? value.slice(0, 9) + '...' : value
        }
        radarOption.series[0].symbolSize = 5
        radarOption.series[0].data[0].value = res.res.map(item => {
          return item.averageRate
        })
        this.skillAnalysisRadarChart = this.$echarts.init(this.$refs.skillAnalysisRadarChart)
        this.skillAnalysisRadarChart.setOption(radarOption)
        this.$nextTick(() => {
          //技能整体掌握柱状图
          let barOption = JSON.parse(JSON.stringify(barHorizontalChart.option))
          barOption.grid.top = 33
          barOption.grid.bottom = 26
          barOption.grid.right = 10
          barOption.grid.left = 66
          barOption.xAxis.axisLabel.fontSize = '12px'
          barOption.yAxis[0].axisLabel.fontSize = '12px'
          barOption.yAxis[0].axisLabel.width = 55
          barOption.yAxis[0].axisLabel.overflow = 'truncate'
          barOption.yAxis[0].nameTextStyle.fontSize = '13px'
          barOption.yAxis[0].data = res.res
            .map(item => {
              return item.abilityName
            })
            .reverse()
          barOption.yAxis[1].axisLabel.fontSize = '12px'
          barOption.yAxis[1].axisLabel.align = 'right'
          barOption.yAxis[1].axisLabel.margin = -3
          barOption.yAxis[1].nameTextStyle.fontSize = '13px'
          barOption.yAxis[1].nameTextStyle.align = 'right'
          barOption.yAxis[1].data = res.res
            .map(item => {
              return this.$roundFloat(item.averageRate, 2) + '%'
            })
            .reverse()
          barOption.series[0].data = res.res
            .map(item => {
              return this.$roundFloat(item.averageRate, 2)
            })
            .reverse()
          this.skillOverallBarChart = this.$echarts.init(this.$refs.skillOverallBarChart)
          this.skillOverallBarChart.setOption(barOption)
        })
      })
    },
    getMainSkillScore() {
      examProcessApi.getMainSkillScore(this.formData).then(res => {
        this.skillList = res.res.map(skill => {
          return {
            id: this.$generateUUID(),
            distributionChart: null,
            ...skill
          }
        })
        // this.$nextTick(() => {
        //   this.skillList = this.skillList.map(skill => {
        //     //技能详细掌握柱状图
        //     let barOption = JSON.parse(JSON.stringify(barLineChart.option))
        //     barOption.xAxis.data = skill.statisticsVOS.map(item => {
        //       return item.space
        //     })
        //     barOption.series[0].data = skill.statisticsVOS.map(item => {
        //       return item.num
        //     })
        //     barOption.series[1].data = skill.statisticsVOS.map(item => {
        //       return item.frequency
        //     })
        //     //技能分布数据图
        //     skill.distributionChart = this.$echarts.init(this.$refs[`distributionChart-${skill.id}`][0])
        //     skill.distributionChart.setOption(barOption)
        //     return skill
        //   })
        // })
      })
    },
    getEvaluationLevel() {
      //评价级别
      examProcessApi.getEvaluationLevel(this.formData).then(res => {
        this.skillPieList = res.res.map(skill => {
          return {
            id: this.$generateUUID(),
            pieChart: null,
            ...skill
          }
        })
        this.$nextTick(() => {
          this.skillPieList = this.skillPieList.map(skill => {
            //技能详细掌握雷达图
            let pieOption = JSON.parse(JSON.stringify(pieChart.option))
            pieOption.series[0].data = skill.evaluationVOS.map(item => {
              return { value: item.rate, name: item.evaluationName }
            })
            //技能分布数据图
            skill.pieChart = this.$echarts.init(this.$refs[`skillPieChart-${skill.id}`][0])
            skill.pieChart.setOption(pieOption)
            return skill
          })
        })
      })
    },
    getSubSkillScore() {
      //子技能
      examProcessApi.getSubSkillScore(this.formData).then(res => {
        this.subSkillList = res.res.map(skill => {
          skill.abilityRateVOList = skill.abilityRateVOList.map(item => {
            return {
              id: this.$generateUUID(),
              distributionChart: null,
              ...item
            }
          })
          return {
            id: this.$generateUUID(),
            ...skill
          }
        })
        this.$nextTick(() => {
          this.subSkillList = this.subSkillList.map(skill => {
            //子技能详细分析
            skill.abilityRateVOList = skill.abilityRateVOList.map(subSkill => {
              let barOption = JSON.parse(JSON.stringify(barLineChart.option))
              barOption.xAxis.data = subSkill.statisticsVOS.map(item => {
                return item.space
              })
              barOption.series[0].data = subSkill.statisticsVOS.map(item => {
                return item.num
              })
              barOption.series[1].data = subSkill.statisticsVOS.map(item => {
                return item.frequency
              })
              //技能分布数据图
              subSkill.distributionChart = this.$echarts.init(this.$refs[`distributionChart-${subSkill.id}`][0])
              subSkill.distributionChart.setOption(barOption)
              return subSkill
            })
            return skill
          })
        })
      })
    },
    exportPdf() {
      pdfD2(document.getElementById('examReport'), '考试结果分析报告.pdf')
        .then(() => {
          this.$message.success('导出成功!')
        })
        .catch(() => {
          this.$message.error('导出失败!')
        })
        .finally(() => {
          this.$parent.loading = false
        })
    },
    backTop() {
      document.querySelector('.skill-report-dialog').scrollTop = 0
    }
  }
}
</script>

<style lang="less" scoped>
.result-container {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background-color: #fff;
  .block {
    width: 100%;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    text-align: left;
    p {
      font-size: @default;
      font-weight: bold;
      color: #333333;
    }
    li {
      .flexStyle(flex, space-between);
      height: 32px;
      padding: 0 5px;
      border-bottom: 1px solid #f0f0f0;
      font-size: 12px;
      .label {
        .flexStyle(flex, flex-start);
      }
      .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }
  .top {
    .flexStyle(flex, flex-start, flex-start);
    height: 220px;
    .block {
      height: 100%;
    }
    .pie-container {
      width: 390px;
      margin: 0 10px;
      flex-shrink: 0;
    }
    //echarts图
    .chart {
      width: 100%;
      height: 185px;
    }
  }
  .analysis-container {
    .flexStyle(flex, center, flex-start);
    padding: 20px 0 10px;
    .item {
      width: 33%;
      &.has-border {
        position: relative;
        &:after {
          content: '';
          width: 1px;
          height: 100px;
          background-color: #e2e4e8;
          position: absolute;
          top: 47px;
          right: 0;
        }
      }
    }
  }
  .skill-block-wrapper {
    height: 413px;
    padding-top: 10px;

    .skill-block {
      height: 100%;
      background-color: #ffffff;
    }
  }
}
</style>
